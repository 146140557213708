<template>
  <b-card>
    <div>
      <span><h4>Add Vulnerabilities</h4></span>
      <validation-observer ref="addVulnerability">
      <div>
        <label>Name</label>
        <validation-provider #default="{ errors }" name="Name" rules="required">
          <input
            v-model="vulnerabilitiesName"
            placeholder="name"
            class="form-control input-sm flex"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <!-- <span class="validation" v-if="duplicateName"> Please enter valid name</span> -->
      </div>
      <label>Description</label>
      <b-form-textarea
        rows="1"
        v-model="vulnerabilitiesDescription"
        placeholder="description"
        class="mr-bottom"
      />
      <div class="mr-bottom">
        <label>Asset category</label>
        <v-select
          @search="getSearch"
          v-model="searchSelectedData"
          multiple
          label="text"
          :options="searchSelectedOptions"
        />
        <!-- {{searchSelectedData}} -->
      </div>
      <label for="">Vulnerability value</label>
      <validation-provider
        #default="{ errors }"
        name="Vulnerability value"
        rules="required"
        class="mb-1"
      >
        <select name="" id="" class="form-control " v-model="vulval">
          <option value="1">Very Low</option>
          <option value="2">Low</option>
          <option value="3">Medium</option>
          <option value="4">High</option>
          <option value="5">Very High</option>
        </select>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <div class="align-right mt-1">
        <button
          @click="addVulnerabilities()"
          class="btn pointer btn-primary btn-sm margin-right"
        >
          Add
        </button>
        <button @click="cancel()" class="btn pointer btn-sm">Cancel</button>
      </div>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import Vue from "vue";
import {
  BFormTextarea,
  BSidebar,
  VBToggle,
  BPagination,
  BCard,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
export default {
  data: () => ({
    allVulnerabilities: [],
    allVulnerabilitiesnew: [],
    allAssets: [],
    // userOption: [],
    newUserOption: [],
    autoData: [],
    vulnerabilitiesDescription: "",
    vulnerabilitiesName: "",
    addVulnerabilitiesData: false,
    editVulnerabilitiesData: false,
    editVulnerabilitiesID: "",
    hideSideBar: false,
    duplicateName: false,
    currentPage: 1,
    lastPage: null,
    perPage: 10,
    rows: 1,
    searchResult: "",
    searchResultAsset: "",
    selectedAsset: [],
    dir: "ltr",
    seletedID: [],
    searchSelectedData: [],
    searchSelectedOptions: [],
    selectedAssetEdit: [],
    newUserOptionEdit: [],
    selectedAssetEditnew: [],
    selectedAssetEditOptionNew: [],
    vulval: "",
    required,
    // selected
  }),
  components: {
    BFormTextarea,
    BSidebar,
    BPagination,
    vSelect,
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {},
  watch: {},
  mounted() {
    this.getVulnerabilities(this.currentPage);
    this.getAssets();
  },
  methods: {
    getVulnerabilities(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerabilities`,
          params: { page: num },
        };
        this.$http(options)
          .then((res) => {
            this.allVulnerabilitiesnew = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.rows = this.lastPage * 10;
            console.log(this.currentPage, this.lastPage, this.rows);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getVulnerabilitiesSearchData(id, num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_ONECSF_API_URL +
            `/vulnerabilities?asset_category_id=${id}`,
          params: { page: num },
        };
        this.$http(options)
          .then((res) => {
            this.allVulnerabilities = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.rows = this.lastPage * 10;
            console.log(this.currentPage, this.lastPage, this.rows);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getSearchByName(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_ONECSF_API_URL +
            `/vulnerabilities?name=${this.searchResult}`,
          params: { page: num },
        };
        this.$http(options)
          .then((res) => {
            this.allVulnerabilities = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.rows = this.lastPage * 10;
            console.log(this.currentPage, this.lastPage, this.rows);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    detailVulnerabilities(id) {
      this.$router.push({ name: "detailVulnerabilities", params: { id: id } });
      // this.$root.$emit('VulnerabilitiesDetail', data)
    },
    getAssets() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/ast_categories`,
      };
      this.$http(options)
        .then((res) => {
          // this.userOption = res.data.data.data
          this.newUserOptionEdit = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      // }
    },
    getSearch(name) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/ast_category/search?name=${name}`,
      };
      this.$http(options)
        .then((res) => {
          // this.searchSelectedData = []
          this.searchSelectedOptions = [];
          this.autoData = res.data.data;
          for (let [key, value] of Object.entries(this.autoData)) {
            let a = {
              value: value._id,
              text: value.name,
            };
            this.searchSelectedOptions.push(a);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addVulnerabilities() {
        this.$refs.addVulnerability.validate().then(valid => {
        if (!valid) {
          // do stuff if not valid.
        }
        else{
             const data = this.searchSelectedData;
      const val = [];
      data.forEach((ele) => {
        val.push(ele.value);
      });
      const body = {
        name: this.vulnerabilitiesName,
        description: this.vulnerabilitiesDescription,
        asset_category_id: val,
        vulnerability_value: this.vulval,
      };
      const options = {
        method: "POST",
        data: body,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerabilities`,
      };
      this.$http(options)
        .then((res) => {
          this.getVulnerabilities();
          this.$router.push({ name: "vulnerabilities" });
          //  val = []
          this.hideSideBar = false;
          this.duplicateName = false;
          this.vulnerabilitiesName = "";
          this.vulnerabilitiesDescription = "";
          this.selectedAsset = [];
        })
        .catch((err) => {
          console.log(err);
        });
        }
      });
     
      //    }
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.mr-right {
  margin-right: 10px;
}
.width-70 {
  width: 70%;
}
.header {
  font-size: 20px;
  font-weight: 600;
}
.align-center-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.align-center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.padding-20 {
  padding: 20px;
}
// .table:not(.table-dark):not(.table-light)
// thead:not(.thead-dark) th,
// .table:not(.table-dark):not(.table-light)
// tfoot:not(.thead-dark) th{
//     background-color: #f3f2f7 !important;
//     color:#ffff
// }
.align-right {
  display: flex;
  justify-content: flex-end;
}
.full-width {
  width: 100%;
}
.icon-box-new {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}
.text-primary-black {
  color: #ffff;
}
.mr-bottom {
  margin-bottom: 20px;
}
.pointer {
  cursor: pointer;
}
.validation {
  color: red;
  font-size: 12px;
  position: relative;
  top: -10px;
}
.table th,
.table td {
  border-top: 1px solid #e3e0e9;
}
.rowgroup tr:hover {
  background-color: #bfcad0;
  cursor: pointer;
}
.text-align-right {
  text-align: right;
}
.width-20 {
  width: 20%;
}
.flex {
  display: flex;
}
</style>
